<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="justify-content-center">
      <CCol md="8">
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <CForm>
                <h1>Login</h1>
                <p class="text-muted">Ingresa a tu cuenta</p>
                <CInput
                  placeholder="Usuario"
                  autocomplete="username email"
                  v-model='email'
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="Contraseña"
                  type="password"
                  autocomplete="curent-password"
                  v-model='password'
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CRow>
                  <CCol col="6">
                    <CButton color="primary" class="px-4" @click="loginUser">Ingresar</CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" class="px-0">Recuperar contraseña</CButton>
                  </CCol>
                </CRow>
              </CForm>
              <div class="alerta-custom" v-show="alert.show" :style="'color: ' + alert.type">
                {{alert.message}}
              </div>
            </CCardBody>
          </CCard>
          <CCard
            color="primary"
            text-color="white"
            class="text-center py-5 d-md-down-none"
            style="width:44%"
          >
            <CCardBody class="d-flex justify-content-center align-items-center">
              <img src="../../assets/imgs/logo-iduam.png" width="100%">
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { login } from '../../api/user'

export default {
  name: 'Login',
  data(){
    return{
      email: '',
      password: '',
      alert: {
        show: false,
        message: '',
        type: 'black'
      }
    }
  },
  async mounted(){
    const token = await localStorage.getItem('token')
    if(token){
      localStorage.removeItem('token')
      location.reload()
    }
  },
  methods: {
    loginUser(){
      const self = this
      login(this.email, this.password)
        .then(function(resp){
          self.$router.push('Dashboard')
          console.log(resp)
        })
        .catch(function(error){
          self.alert = {
            message: error.message,
            show: true,
            type: 'red'
          }
          setTimeout(function() {
            self.alert = {
              show: false,
              message: '',
              type: 'black'
            }
          }, 4000);
        })
    }
  }
}
</script>
<style type="text/css" media="screen">
  .alerta-custom{
    font-size: 10px;
  }  
</style>